import { React, useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import {Link} from 'react-router-dom'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '../css/map.css'
import pariwisata from '../assets/img/building.png'
import gmap from '../assets/img/gmap.png'
import kesehatan from '../assets/img/hospital-building.png'
import sosbud from '../assets/img/group.png'
import barangjasa from '../assets/img/shipping.png'
import nature from '../assets/img/nature.png'
import infop from '../assets/img/info.png'
import firebaseDB from './firebase'


mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmRpYXRzYW5pIiwiYSI6ImNqajRjMHJ6bzE1ZDAza3I1a252Y2Y0M2kifQ.x8e3S41RKLHRw_wnGftkjA"

export default function Mappotensikec(){

    const mapContainerRef = useRef(null);
    const [lng, setLng] = useState(116.100487);
    const [lat, setLat] = useState(-8.597081);
    const [zoom, setZoom] = useState(12.5);
    const [namaKec, setNamaKec] = useState()

    const [dataHotel,setDataHotel] = useState({})
    const [dataResto,setDataResto] = useState({})
    const [dataRm,setDataRm] = useState({})
    const [dataTvr,setDataTvr] = useState({})
    const [dataEo,setDataEo] = useState({})
    const [dataRs,setDataRs] = useState({})
    const [dataKs,setDataKs] = useState({})

    const [dataKw,setDataKw] = useState()
    const [dataDj,setDataDj] = useState()
    const [dataSb,setDataSb] = useState()
    const [dataLh,setDataLh] = useState()
    const [dataPkm,setDataPkm] = useState({})

    const [namaKel, setNamaKel] = useState()

    const [counterArr,setCounterArr] = useState([])

    const listkecamatan = [
        "Ampenan",
        "Cakranegara",
        "Mataram",
        "Sandubaya",
        "Sekarbela",
        "Selaparang"    

    ]

    const jmlklurahan = [
        "10 Kelurahan",
        "10 Kelurahan",
        "9 Kelurahan",
        "7 Kelurahan",
        "5 Kelurahan",
        "9 Kelurahan",


    ]

    const listwarnakec = [
        "#B30000",
        "#e618f4",
        "#1C4E87",
        "#FFD1BA",
        "#a8c3f4",
        "#00b300",
    
    ]

    const listklurahanamp = [
        "ampenan_selatan",
        "ampenan_tengah",
        "ampenan_utara",
        "banjar",
        "bintaro",
        "dayan_peken",
        "kebon_sari",
        "pejarakan_karya",
        "pejeruk",
        "taman_sari"
    ]

    const listklurahancak = [
        "cakranegara_barat",
        "cakranegara_selatan",
        "cakranegara_selatan_baru",
        "cakranegara_timur",
        "cakranegara_utara",
        "cilinaya",
        "karang_taliwang",
        "mayura",
        "sapta_marga",
        "sayang_sayang"
    ]

    const listklurahanmtr = [
        "mataram_timur",
        "pegesangan",
        "pegesangan_barat",
        "pegesangan_timur",
        "pagutan",
        "pagutan_barat",
        "pagutan_timur",
        "pejanggik",
        "punia"
    ]

    const listklurahansand = [
        "babakan",
        "bertais",
        "dasan_cermen",
        "mandalika",
        "selagalas",
        "turida",
        "abian_tubuh_baru"
    ]

    const listklurahanskr = [
        "jempong_baru",
        "karang_pule",
        "kekalik_jaya",
        "tanjung_karang",
        "tanjung_karang_permai"
    ]

    const listklurahanslp = [
        "dasan_agung",
        "dasan_agung_baru",
        "gomong",
        "karang_baru",
        "mataram_barat",
        "monjok",
        "monjok_barat",
        "monjok_timur",
        "rembiga"
    ]

    function getdataampenan(e){

        setNamaKel(e)
      try {
        firebaseDB.database().ref().child(e).child('hotel').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
            setDataHotel({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('restoran').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
            setDataResto({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('rumahmakan').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataRm({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('travel').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataTvr({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('eventorganizer').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataEo({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('rumahsakit').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataRs({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('klinikdanspesialis').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataKs({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('puskesmas').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataPkm({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }
    }

    useEffect(() => { 
        
        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/light-v10',
          center: [lng, lat],
          zoom: zoom
        });

        map.addControl(
          new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
          })
        );
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.FullscreenControl());
        map.on('move', () => {
          setLng(map.getCenter().lng.toFixed(4));
          setLat(map.getCenter().lat.toFixed(4));
          setZoom(map.getZoom().toFixed(2));
        });
        map.on('load', function() {

          map.addSource('states', {
          'type': 'geojson',
          'data':
          'https://raw.githubusercontent.com/LaluRyandi/mapbox-gl-sepadan/master/mtr.geojson'
          });
  
          map.addLayer({
          'id': 'states-layer',
          'type': 'fill',
          'source': 'states',
          'paint': {
          'fill-color': [
            'match',
            ['get', 'KEC'],
            'Ampenan','#B30000',
            'Cakranegara','#e618f4',
            'Mataram','#1C4E87',
            'Sandubaya','#FFD1BA',
            'Sekarbela','#a8c3f4',
            'Selaparang','#00b300',
            '#ccc'
          ],
          'fill-outline-color': 'rgba(0, 0, 0, 1)',
          'fill-opacity' : 0.7}
          });
       
          map.on('click', 'states-layer', function(e) {
          
            setNamaKec(e.features[0].properties.KEC)
            if (e.features[0].properties.KEC=="Ampenan") {
             
                setCounterArr(listklurahanamp)
                setDataKw('Kawasan eks. Bandar Udara Selaparang di kelurahan Ampenan Utara sebagai kawasan pariwisata dengan konsep MICE yang berbasis lingkungan, Kawasan Udayana di Kelurahan Kebonsari dan Kelurahan Pejarakan Karya, Kawasan Kota Tepian Air di Kelurahan Bintaro, Kelurahan Ampenan Tengah, dan Kelurahan Ampenan Selatan')
                setDataDj('Pusat perdagangan Ampenan di Kelurahan Dayen Peken, Kelurahan Ampenan Tengah, dan Kelurahan Ampenan Selatan')
                setDataSb('Kawasan Bintaro di Kelurahan Bintaro, Kawasan Kota Tua Ampenan di kelurahan Ampenan Tengah dan Ampenan Selatan')
                setDataLh('Tidak ada data')
            }else  if (e.features[0].properties.KEC=="Cakranegara") {
                
                setCounterArr(listklurahancak)
                setDataKw('Kawasan Sayang-sayang di Kelurahan Sayang-sayang sebagai kawasan pariwisata kuliner, Kawasan Mayura yang terdiri dari Taman Mayura, Pura Meru, dan kolam pemandian Mayura di kelurahan Mayura')
                setDataDj('Pusat perdagangan grosir dan pusat bisnis Cakranegara di Kelurahan Cakranegara Barat, Kelurahan Cilinaya, Kelurahan Mayura, Kelurahan Cakranegara Timur, dan Kelurahan Cakranegara Selatan')
                setDataSb('Kawasan Makam Van Ham di Kelurahan Cilinaya')
                setDataLh('Kawasan lindung di kelurahan sayang-sayang')

            }else  if (e.features[0].properties.KEC=="Mataram") {
             
                setCounterArr(listklurahanmtr)
                setDataKw('Kawasan Mutiara Sekarbela di Kelurahan Pagesangan, Kelurahan Pagesangan Barat')
                setDataDj('Tidak ada data')
                setDataSb('Tidak ada data')
                setDataLh('Kawasan lingdung di kelurahan Pagutan Timur')
            }else  if (e.features[0].properties.KEC=="Sandubaya") {
             
                setCounterArr(listklurahansand)
                setDataKw('Tidak ada data')
                setDataDj('Kawasan Bertais dan Kawasan Mandalika')
                setDataSb('Tidak ada data')
                setDataLh('Kawasan lingdung di kelurahan bertais')
            }else  if (e.features[0].properties.KEC=="Sekarbela") {
             
                setCounterArr(listklurahanskr)
                setDataKw('Kawasan Mutiara Sekarbela kelurahan Karang Pule ')
                setDataDj('Tidak ada data')
                setDataSb('Tidak ada data')
                setDataLh('Tidak ada data')
            }else  if (e.features[0].properties.KEC=="Selaparang") {
             
                setCounterArr(listklurahanslp)
                setDataKw('Kawasan Sayang-sayang di Kelurahan Rembiga sebagai kawasan pariwisata kuliner.')
                setDataDj('Tidak ada data')
                setDataSb('Kawasan Pusat Kajian Islam (Islamic Center) di Kelurahan Dasan Agung;')
                setDataLh('Tidak ada data')
            }
            // var kelurahan = e.features[0].properties.KELDES;

           
            // if (kelurahan=="Tanjungkarang Permai") {
            //   kelurahan="tanjung_karang_permai"
            // } else  if (kelurahan=="Madalika") {
            //   kelurahan="mandalika"
            // }
            // else {
            //   kelurahan = kelurahan.toLowerCase();
            // }

        //     if (kelurahan2=="Madalika") {
        //       kelurahan2="Mandalika"
        //     }

            // var finalc= kelurahan.replace(/ /g,"_")
          
            
        //     setNamaKelurahan( kelurahan2)
        //     setNamaKecamatan('Kec. '+e.features[0].properties.KEC)
        //     getdatahotel(finalc)
        //     getdataKw(finalc)
        //     getdataDj(finalc)
        //     getdataLh(finalc)
        //     getdataSb(finalc)

          window.$('#kecmodal').modal('show')
              
              });
      
      
       
          map.on('mouseleave', 'states-layer', function() {
          map.getCanvas().style.cursor = '';
          });
          map.on('mousemove', function(e) {
               
              var features = map.queryRenderedFeatures(e.point, { layers: ['states-layer'] });
              map.getCanvas().style.cursor = features.length ? 'pointer' : '';
            });
        });


  }, []);
    return(
        <>
      <div className='map-container' ref={mapContainerRef} />
      <div className="rounded-6  rightc ms-3 me-3  p-1" style={{backgroundColor:'rgba(0,0,0,0.2)',marginTop:'1%'}} role="group">
          <Link  to="./petapotensi" style={{textDecoration: 'none'}} type="button" className="rounded-6 pt-2 pb-2  ps-4 pe-4" >Kelurahan</Link>
          <button type="button" className=" text-muted ps-4 pt-2 pb-2 pe-4 rounded-6" style={{backgroundColor:'#ffffff'}}>Kecamatan</button>
          {/* <Link to="./peta" style={{textDecoration: 'none'}} type="button" className=" text-muted ps-4 pt-2 pb-2 pe-4 rounded-6">Pola Ruang</Link> */}
        </div>
        <div className=" rounded-6 rightc ms-3 me-3 " style={{backgroundColor:'rgba(0,0,0,0)',width: '24rem',height:'90vh',marginTop:'8vh'}}>
          <div className="card-body ">

            <div className="card p-3 rounded-6 shadow">
            <h6 className="card-subtitle mb-2 text-muted">Panel Potensi Kota Mataram</h6>
            <div className="p-5">
            <div className="row ">
              <div className="col-md-4">
              <Link to="./kwisata"><img style={{cursor:'pointer'}}  src={pariwisata}   width={48} height={48}/></Link>
              </div>
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}  src={barangjasa} data-bs-toggle="modal" data-bs-target="#kawasandagangjasa" width={48} height={48}/>
              </div>
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}src={sosbud}  data-bs-toggle="modal" data-bs-target="#kawasansosbud" width={48} height={48}/>
              </div>
              
            </div><br/>
            <div className="row ">
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}  src={nature} data-bs-toggle="modal" data-bs-target="#kawasanlingkungan" width={48} height={48}/>
              </div>
              <div className="col-md-4">
              <Link to="./faskes"><img style={{cursor:'pointer'}} src={kesehatan} width={48} height={48}/></Link>
              </div>
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}   src={infop} data-bs-toggle="modal" data-bs-target="#infopotensimodal" width={48} height={48}/>
              </div>
              
            </div>
            </div>
            </div>

   

            <div className="card p-4 rounded-6 shadow mt-1">
            <h6 className="card-subtitle mb-2 text-muted">Legend Map ( List Kecamatan )</h6>
            <ul   className="list-group list-group-numbered">

            {listkecamatan.map((listkecamatan,index)=>
                   
                   <div >
                          <li style={{cursor: 'pointer'}} key={index} className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{listkecamatan}</div>
                             {jmlklurahan[index]}
                            </div>
                        
                            <span style={{backgroundColor : listwarnakec[index]}} className="badge rounded-pill">&nbsp;</span>
                        
                          </li>
                   </div>
                 )}


            </ul>
            </div>

            </div>
          </div>


                  
        

              {/* Modal Perdagangan dan Jasa*/}
            <div className="modal fade" id="kawasandagangjasa" tabIndex={-1} aria-labelledby="kawasandagangjasaLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Kawasan Perdagangan &amp; Jasa</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="kwunggulan-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kwunggulan" aria-expanded="false" aria-controls="kwunggulan">
                            <h6 class="mb-0">Kawasan Bisnis/Unggulan : </h6>
                            </button>
                          </h2>
                          <div id="kwunggulan" className="accordion-collapse collapse" aria-labelledby="kwunggulan-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                  1. Pusat perdagangan Ampenan di Kelurahan Dayen Peken, Kelurahan Ampenan Tengah, dan Kelurahan Ampenan Selatan (Kecamatan Ampenan);<br/><br/>
                                  2. Pusat perdagangan grosir dan pusat bisnis Cakranegara di Kelurahan Cakranegara Barat, Kelurahan Cilinaya, Kelurahan Mayura, Kelurahan Cakranegara Timur, dan Kelurahan Cakranegara Selatan;<br/><br/>
                                  3. Kawasan Bertais dan Kawasan Mandalika
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Sosial Budaya*/}
            <div className="modal fade" id="kawasansosbud" tabIndex={-1} aria-labelledby="kawasansosbud" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Kawasan Sosial Budaya</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="kwunggulan-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kwunggulan" aria-expanded="false" aria-controls="kwunggulan">
                            <h6 class="mb-0">Kawasan Bisnis/Unggulan : </h6>
                            </button>
                          </h2>
                          <div id="kwunggulan" className="accordion-collapse collapse" aria-labelledby="kwunggulan-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                1. Kawasan Bintaro di Kelurahan Bintaro (Kecamatan Ampenan);<br/><br/>
                                2. Kawasan Makam Van Ham di Kelurahan Cilinaya (Kecamatan Cakranegara);<br/><br/>
                                3. Kawasan Pusat Kajian Islam (Islamic Center) di Kelurahan Dasan Agung;<br/><br/>
                                4. Kawasan Kota Tua Ampenan di kelurahan Ampenan Tengah dan Ampenan Selatan (Kecamatan Ampenan).
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          
            {/* Modal Kawasan Lingkungan Hidup*/}
            <div className="modal fade" id="kawasanlingkungan" tabIndex={-1} aria-labelledby="kawasanlingkungan" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Kawasan Lingkungan Hidup</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="kwunggulan-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kwunggulan" aria-expanded="false" aria-controls="kwunggulan">
                            <h6 class="mb-0">Kawasan Bisnis/Unggulan : </h6>
                            </button>
                          </h2>
                          <div id="kwunggulan" className="accordion-collapse collapse" aria-labelledby="kwunggulan-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                1. Kawasan konservasi di sepanjang Sungai Midang, Sungai Jangkok, Sungai Ancar, dan Sungai Brenyok;<br/><br/>
                                2. Kawasan koservasi sempadan pantai Selat Lombok sepanjang 8-9 km;<br/><br/>
                                3. Kawasan lingdung di kelurahan Pagutan Timur (Kecamatan Mataram) serta kelurahan Sayang-sayang dan Selagalas (Kecamatan Sandubaya);<br/><br/>
                                4. Kawasan hutan Kota di tiap tanah pecatu yang terdapat di Kota Mataram.
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            
       
               {/* Modal Info Potensi*/}
               <div className="modal fade" id="infopotensimodal" tabIndex={-1} aria-labelledby="infopotensimodal" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Potensi Kota Mataram</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ipkep-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ipkep" aria-expanded="false" aria-controls="ipkep">
                            <h6 class="mb-0">kawasan potensi pendukung ekonomi cepat tumbuh</h6>
                            </button>
                          </h2>
                          <div id="ipkep" className="accordion-collapse collapse" aria-labelledby="ipkep-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                1. Kawasan Strategis Bidang Pariwisata : Kawasan Mutiara Sekarbela, Kawasan Rekreasi Pantai Dan Situs Makam Loang Baloq<br/><br/>
                                2. Kawasan Strategis Bidang Perdagangan Dan Jasa : pusat perdagangan grosir dan bisnis di Cakranegara, Ampenan dan Bertais<br/><br/>
                                3. Kawasan Strategis Dari Sudut Kepentingan Sosial Budaya : Pusat Kajian Islam di Kelurahan Dasan Agung dan Kawasan Kota Tua Ampenan<br/><br/>
                                4. Kawasan Strategis Bidang Daya Dukung Lingkungan Hidup : Kawasan Lindung di Pagutan Timur, Sayang-Sayang dan Selagalas
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ipsip-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ipsip-collapseTwo" aria-expanded="false" aria-controls="ipsip-collapseTwo">
                            <h6 class="mb-0">Komoditas penunjang perkembangan sektor industry pengolahan</h6>
                            </button>
                          </h2>
                          <div id="ipsip-collapseTwo" className="accordion-collapse collapse" aria-labelledby="ipsip-headingTwo" data-bs-parent="#accordionFlushExample">
                           <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                  1. Budidaya kangkung di Pesongoran<br/><br/>
                                  2. Industri tahu dan tempe di Kekalik dan Abian Tubuh<br/><br/>
                                  3. Industri olahan rumput laut di Cakranegara<br/><br/>
                                  4. Produksi dan perdagangan perhiasan emas, perak dan mutiara di Sekarbela, Karang Genteng dan Karang Mas-Mas
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ippism-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ippism-collapseThree" aria-expanded="false" aria-controls="ippism-collapseThree">
                            <h6 class="mb-0">Peluang investasi dalam sekala menengah yang potensial di Kota Mataram</h6>
                            </button>
                          </h2>
                          <div id="ippism-collapseThree" className="accordion-collapse collapse" aria-labelledby="ippism-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                  1. Jasa telekomunikasi seluler<br/><br/>
                                  2. Perdagangan<br/><br/>
                                  3. Jasa rekreasi wisata<br/><br/>
                                  4. Jasa konsultasi pengembangan bisnis dan manajemen<br/><br/>
                                  5. Biro perjalanan wisata<br/><br/>
                                  6. Jasa penyediaan gedung perkantoran dan pusat bisnis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ipdppm-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ipdppm-collapseThree" aria-expanded="false" aria-controls="ipdppm-collapseThree">
                            <h6 class="mb-0"> Investasi anda akan didukung dengan penyediaan sarana dan prasarana yang memadai seperti</h6>
                            </button>
                          </h2>
                          <div id="ipdppm-collapseThree" className="accordion-collapse collapse" aria-labelledby="ipdppm-headingThree" data-bs-parent="#accordionFlushExample">
                           <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                    1. UMKM (Usaha Mikro Kecil Menengah)<br/><br/>
                                    2. Tenaga kerja<br/><br/>
                                    3. Ketersediaan listrik<br/><br/>
                                    4. Distribusi air<br/><br/>
                                    5. Infrastruktur jalan dan transportasi<br/><br/>
                                    6. Perbankan
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

                           
            <div class="modal fade" id="kecmodal" tabindex="-1" aria-labelledby="kecmodalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                      
                        <div class="modal-body p-3">
                          <h2 className="mb-5">Kec. {namaKec}</h2>
                          <div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Kawasan Pariwisata</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Kawasan Perdagangan &amp; Jasa</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Kawasan Sosial Budaya</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="nav-sosbud-tab" data-bs-toggle="tab" data-bs-target="#nav-sosbud" type="button" role="tab" aria-controls="nav-sosbud" aria-selected="false">Kawasan Lingkungan Hidup</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="nav-faskes-tab" data-bs-toggle="tab" data-bs-target="#nav-faskes" type="button" role="tab" aria-controls="nav-faskes" aria-selected="false">Fasilitas Kesehatan</button>
                            </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active p-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h6>Kawasan bisnis/unggulan : {dataKw}</h6> <br/>
                            <span>Tampilkan data berdasarkan kelurahan</span>   <br/>
                                    {
                                               Object.keys(counterArr).map(id=>{    
                
                                                  return  <span style={{cursor:'pointer'}} data-bs-toggle="modal" data-bs-target="#hotelModal" onClick={()=> getdataampenan(counterArr[id]) } class="badge bg-primary me-2">{counterArr[id]}</span>
      
                                            })
                                          
                                          }
                              
                             </div>
                            <div className="tab-pane fade p-4" id="profile" role="tabpanel" aria-labelledby="profile-tab"> <h6>Kawasan bisnis/unggulan : {dataDj}</h6> <br/></div>
                            <div className="tab-pane fade p-4" id="contact" role="tabpanel" aria-labelledby="contact-tab"> <h6>Kawasan bisnis/unggulan : {dataLh}</h6> <br/></div>
                            <div className="tab-pane fade p-4" id="nav-sosbud" role="tabpanel" aria-labelledby="nav-sosbud-tab"> <h6>Kawasan bisnis/unggulan : {dataSb}</h6> <br/></div>
                            <div className="tab-pane fade  p-4" id="nav-faskes" role="tabpanel" aria-labelledby="nav-faskes-tab">
                                
                            <span>Tampilkan data berdasarkan kelurahan</span>   <br/>
                                    {
                                               Object.keys(counterArr).map(id=>{    
                
                                                  return  <span style={{cursor:'pointer'}} data-bs-toggle="modal" data-bs-target="#faskeasModal" onClick={()=> getdataampenan(counterArr[id]) } class="badge bg-primary me-2">{counterArr[id]}</span>
      
                                            })
                                          
                                          }
                            </div>
                           
                            </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    

                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="modal fade" id="hotelModal" tabindex="-1" aria-labelledby="hotelModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content shadow">
                      
                        <div class="modal-body">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Hotel  &amp; Penginapan
                                      </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">

                                      <ul class="list-group list-group-numbered">
                                          
                                        {
                                             Object.keys(dataHotel).map(id=>{    
                                              
                                              if (dataHotel[id].nama==undefined) {
                                                return <span>Tidak ada data</span>
                                              }else if (dataHotel[id].geolokasi=="NULL") {
                                                return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                  <div className="fw-bold">{dataHotel[id].nama}</div>
                                                  {dataHotel[id].alamat}
                                                  </div>
                                                  
                                                </li>
                                              }
                                              else{
                                                return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                  <div className="fw-bold">{dataHotel[id].nama}</div>
                                                  {dataHotel[id].alamat}
                                                  </div>
                                                    <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataHotel[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                </li>
                                              }
                                             
                                        
                                             
                                          })
                                        
                                        }
                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                      Kuliner
                                      </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">
                                        <code>A. Restoran</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataResto).map(id=>{    
                                                
                                                if (dataResto[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataResto[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataResto[id].nama}</div>
                                                    {dataResto[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataResto[id].nama}</div>
                                                    {dataResto[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataResto[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
  
                                        <code>B. Rumah Makan</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataRm).map(id=>{    
                                                
                                                if (dataRm[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataRm[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRm[id].nama}</div>
                                                    {dataRm[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRm[id].nama}</div>
                                                    {dataRm[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataRm[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul> 
                                        
                                        </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                      Traveler &amp; Event Organizer
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">
                                        <code>A. Traveler</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataTvr).map(id=>{    
                                                
                                                if (dataTvr[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataTvr[id].nama}</div>
                                                    {dataTvr[id].alamat}
                                                    </div>
                                                     
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul> 
                                        <code>B. Event Organizer</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataEo).map(id=>{    
                                                
                                                if (dataEo[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataEo[id].nama}</div>
                                                    {dataEo[id].alamat}
                                                    </div>
                                                     
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul> 
                                        .</div>
                                    </div>
                                  </div>
                                </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="modal fade" id="faskeasModal" tabindex="-1" aria-labelledby="faskeasModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content shadow">
                      
                        <div class="modal-body">
                        <div className="accordion accordion-flush" id="accordionFlushExample1">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="flush-haedF">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseF" aria-expanded="false" aria-controls="flush-collapseF">
                                      Rumah Sakit
                                    </button>
                                  </h2>
                                  <div id="flush-collapseF" className="accordion-collapse collapse" aria-labelledby="flush-haedF" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    <ul class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataRs).map(id=>{    
                                                
                                                if (dataRs[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataRs[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRs[id].nama}</div>
                                                    {dataRs[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRs[id].nama}</div>
                                                    {dataRs[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataRs[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="flush-headingK">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwoK" aria-expanded="false" aria-controls="flush-collapseTwoK">
                                    Puskesmas
                                    </button>
                                  </h2>
                                  <div id="flush-collapseTwoK" className="accordion-collapse collapse" aria-labelledby="flush-headingK" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    <ul class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataPkm).map(id=>{    
                                                
                                                if (dataPkm[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataPkm[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataPkm[id].nama}</div>
                                                    {dataPkm[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataPkm[id].nama}</div>
                                                    {dataPkm[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataPkm[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="flush-headingThreeE">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreeE" aria-expanded="false" aria-controls="flush-collapseThreeE">
                                    Klinik &amp; Spesialis
                                    </button>
                                  </h2>
                                  <div id="flush-collapseThreeE" className="accordion-collapse collapse" aria-labelledby="flush-headingThreeE" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    <ul class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataKs).map(id=>{    
                                                
                                                if (dataKs[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataKs[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataKs[id].nama}</div>
                                                    {dataKs[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataKs[id].nama}</div>
                                                    {dataKs[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataKs[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
                                          </div>
                                  </div>
                                </div>
                              </div> 

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
                        </div>
                        </div>
                    </div>
                    </div>
                    
        </>
    )
}