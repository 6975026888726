import React, {useEffect, useState} from 'react';
import '../css/styles.css'
import logo1 from '.././assets/img/portrait_black.png';
import tabelluas from '.././assets/img/tabelluas.png';
import demografi1 from '.././assets/img/demografi1.png';
import kinerjaekomoni1 from '.././assets/img/kinerjaekomoni1.png';
import {Link} from 'react-router-dom'
import { StickyNav } from 'react-js-stickynav'
export default function mainpage(){

  const style = () => {
    return (
      <style jsx>{`
        .nav {
          transition: all 0.1s linear;
          position: fixed;
          z-index: 2000;
          padding: 20px;
        }
        .scrollNav {
          transition: all 0.5s ease-in;
          z-index: 2000;
          background: #000000;
          width: 100%;
          border-bottom: 1px solid #dddddd;
        }
        .styl {
          padding-top: 80px;
        }
      `}</style>
    )
  }

    return(
        <>
            {style()}
      {/* Navigation */}
      <StickyNav length='20' >
      <nav className="navbar navbar-expand-lg navbar-light ms-5 " id="mainNav">
        <div className="container">
          {/* Image Logo */}
          {/* Text Logo - Use this if you don't have a graphic logo */}
          {/* <a class="navbar-brand logo-text page-scroll" href="index.html">Mark</a> */}
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              <li className="nav-item">
                <Link to="/petapotensi" className="nav-link" href="#">BUKA PETA</Link>
              </li>
              <li className="nav-item">
                <a target="_blank" href="https://drive.google.com/file/d/1jE0tRHzVnhskW8t8OW_Ngsz_T5YEnj64/view?usp=sharing" className="nav-link" >Panduan Penggunaan</a>
              </li>
            
              <li className="nav-item">
                <a className="nav-link" href="#">Kontak</a>
                {/* soon */}
              </li>
              
            </ul>
          </div>
        </div> {/* end of container */}
        </nav>
      </StickyNav > {/* end of navbar */}
      {/* end of navigation */}

        {/* Header */}
        <header id="header" className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-container text-center">
                <h1 className="h1-large ">Peta Digital Potensi Investasi <br/> Kota Mataram</h1>
               
              </div> {/* end of text-container */}
             </div> {/* end of col */}
            </div> {/* end of row */}
          </div> {/* end of container */}
        </header> {/* end of header */}
        {/* end of header */}
  

      {/* About*/}
      <div id="about" className="basic-1 bg-light">
        <div className="container">
       
          
              <div className="text-center first">
                <h3 className="mb-5">Ayo Investasi di Kota Mataram !</h3>
                <h6>Kota Mataram memiliki potensi dan peluang investasi dalam rangka memacu laju pertumbuhan ekonomi, memberikan kesempatan sebesar-besarnya kepada para investor dan memberikan kemudahan pelayanan untuk menanamkan modalnya di Kota Mataram.</h6>
              </div> {/* end of text-container */}
        
           
           
      
        </div> {/* end of container */}
      </div> {/* end of basic-1 */}
      {/* end of about */}

          {/* Services */}
          <div id="services" className="basic-2 ">
          <div className="container">
            <div className="row">
              <div   className="col-lg-12">
                <h2 className="h2-heading">Gambaran Umum Kota Mataram</h2>
                <p className="p-heading">Mataram merupakan salah satu Kota di Provinsi Nusa Tenggara Barat terletak di ujung Barat pulau Lombok pada posisi antara 08o33’ dan 08o 38’ Lintang Selatan dan di antara 116o 04’ – 116o 10’ Bujur Timur dengan luas wilayah 61,30 KM2</p>
              </div> {/* end of col */}
            </div> {/* end of row */}
            <div className="row">
              <div className="col-lg-6">
                <div className="text-box">
                  <h4>Kondisi Demografi</h4>
              
                  <img className="mt-3" src={tabelluas}></img>
                  <img src={demografi1}></img>
                </div> {/* end of text-box */}
              </div> {/* end of col */}
              <div className="col-lg-6">
                <div className="text-box">
                  <h4>Kinerja Ekonomi</h4>
                  <img className="mt-3" src={kinerjaekomoni1}></img>
                </div> {/* end of text-box */}
              </div> {/* end of col */}
          
              </div> {/* end of row */}
            </div> {/* end of container */}
          </div> {/* end of basic-2 */}
          {/* end of services */}

             {/* Section Divider */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <hr className="section-divider" />
            </div> {/* end of col */}
          </div> {/* end of row */}
        </div> {/* end of container */}
        {/* end of section divider */}
        {/* Questions */}

                  {/* Services */}
          <div id="services" className="basic-2 bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="h2-heading mb-5">Potensi dan Daya Dukung Investasi</h2>
           
              </div> {/* end of col */}
            </div> {/* end of row */}
            <div className="row">
              <div className="col-lg-4">
                <div className="text-box">
                  <h5 className="mb-3">Daya Tarik Sektor Industri Pengolahan</h5>
              
                 <p >Kota Mataram dikenal oleh para wisatawan dengan cinderamata yang khas berupa makanan den perhiasan. Kangkung, Tahu dan Tempe, telur asin serta produk olahan dari Rumput Laut saat ini menjadi icon Kota Mataram bagi masyarakat luar.Kota Mataram memiliki beberapa sentra wilayah pengembangan komoditas penunjang perkembangan sektor industri pengolahan antara lain :</p>
                <br/>
                <p  className="mb-3">1. Pesongoran lokasi budidaya kangkung</p>
                <p  className="mb-3">2. Kekalik dan Abian Tubuh lokasi industri Tahu dan Tempe</p>
                <p className="mb-3">3. Cakranegara lokasi industri olahan rumput laut dan pusat perdagangan konveksi</p>
                <p className="mb-3">4. Sekarbela, Karang mas-mas dan Karang Genteng sebagai pusat produksi dan perdagangan perhiasan Emas, Perak dan Mutiara</p>
                </div> {/* end of text-box */}
              </div> {/* end of col */}


              <div className="col-lg-4">
                <div className="text-box">
                  <h5 className="mb-3">Daya Tarik Sektor Perdagangan, Hotel dan Restoran</h5>
                  <p >Daya tarik sektor perdagangan, Hotel dan Restoran di Kota Mataram sangat beragam dari daya tarik atraksi seni dan budaya, wisata budaya, wisata minat khusus dan wisata alam. Daya tarik wisata tersebut diperkuat lagi dengan tiga aspek berikut:</p>
                  <br/>
                  <p className="mb-3">1. Kota Mataram sebagai ibu Kota Propinsi NTB menjadi pusat segala kegiatan seperti Pusat Pemerintahan, Pusat Pendidikan, Pusat perekonomian, Pusat Seni dan Budaya.</p>
                <p className="mb-3">2. Merupakan pintu gerbang utama bagi wisatawan yang  mengunjungi obyek-obyek wisata di Kabupaten kota lainnya di NTB</p>
                <p className="mb-3">3. Berada di wilayah Segi Tiga Emas Daerah tujuan wisata Bali, Tanah Toraja dan Pulau Komodo</p>
               
                </div> {/* end of text-box */}
              </div> {/* end of col */}

              <div className="col-lg-4">
                <div className="text-box">
                  <h5 className="mb-3">Daya Tarik Sektor Perdagangan, Hotel dan Restoran</h5>
                  <p className="mb-1">Salah satu indikator berkembang pesatnya sektor keuangan, Persewaan dan Jasa Perusahaan di Kota Mataram ditunjukan oleh semakin banyaknya pihak pengelola jasa perbankan dan perusahaan pendukung perekonomian yang membuka kantor di Kota Mataram, pada tiga tahun terakhir tedapat bank-bank swasta papan atas nasional melirik dan membuka cabang di Kota Mataram, jasa perusahaan penunjang perekonomian seperti jasa ekspedisi telah berkembang pesat dalam rangka mendukung arus ekspor dan impor dari dan ke Kota Mataram.</p>
             
               
                </div> {/* end of text-box */}
              </div> {/* end of col */}
          
              </div> {/* end of row */}
            </div> {/* end of container */}
          </div> {/* end of basic-2 */}
          {/* end of services */}

        <div className="accordion-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="h2-heading">Aturan Terkait Investasi/Penanaman Modal</h2>
              </div> {/* end of col */}
            </div> {/* end of row */}
            <div className="row">
              <div className="col-lg-12">
                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                  UU No 25 Tahun 2007 Tentang Penanaman Modal 
                </a>
                <a href="#" className="list-group-item list-group-item-action">UU No 11 Tahun 2020 Tentang Cipta Kerja </a>
                <a href="#" className="list-group-item list-group-item-action">PP No 5 Tahun 2021 Tentang Penyelenggaraan PBBR </a>
                <a href="#" className="list-group-item list-group-item-action">PP No 6 Tahun 2021 Tentang Penyelenggaraan Perizinan Berusaha Di Daerah </a>
                <a className="list-group-item list-group-item-action disabled">Peraturan Presiden No 10 Tahun 2021 Tentang Bidang Usaha Penanaman Modal </a>
                <a className="list-group-item list-group-item-action disabled">Peraturan Kepala PKPM No 5 Tahun 2021 Tentang Pedoman dan Tata Cara Penawasan PBBR</a>
              </div> {/* end of col */}
            </div> {/* end of row */}
          </div> {/* end of container */}
        </div> {/* end of accordion-1 */}
        {/* end of questions */}


    


        {/* Copyright */}
        <div className="copyright bg-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="p-small">Copyright 2022 © <a className="no-line" href="#your-link">DPMPTSP</a><p onClick={()=> alert("dev : L. ahmad gede .p.a  :) | CP 2022 DPMPTSP")} className="no-line" >....</p></p>
              </div> {/* end of col */}
            </div> {/* enf of row */}
          </div> {/* end of container */}
         
        </div> {/* end of copyright */} 
        {/* end of copyright */}
        {/* this pages basic template Distributed By Themewagon and included some images */}
        </>
    )
}