import { React, useRef, useEffect, useState } from 'react';


export default function Panduan(){

    return(
        <>
        a
        </>
    )
}