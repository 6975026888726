import { React, useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import {Link} from 'react-router-dom'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '../css/map.css'
import pariwisata from '../assets/img/building.png'
import gmap from '../assets/img/gmap.png'
import kesehatan from '../assets/img/hospital-building.png'
import sosbud from '../assets/img/group.png'
import barangjasa from '../assets/img/shipping.png'
import nature from '../assets/img/nature.png'
import infop from '../assets/img/info.png'
import firebaseDB from './firebase'


mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmRpYXRzYW5pIiwiYSI6ImNqajRjMHJ6bzE1ZDAza3I1a252Y2Y0M2kifQ.x8e3S41RKLHRw_wnGftkjA"



export default function Mappotensi(){

    
    const mapContainerRef = useRef(null);
    
    const [lng, setLng] = useState(116.100487);
    const [lat, setLat] = useState(-8.597081);
    const [zoom, setZoom] = useState(12.5);
    const [namaKelurahan, setNamaKelurahan] = useState();
    const [namaKecamatan, setNamaKecamatan] = useState('klik salah satu wilayah kelurahan di map untuk menampilkan detail...');
    const [dataHotel,setDataHotel] = useState({})
    const [dataResto,setDataResto] = useState({})
    const [dataRm,setDataRm] = useState({})
    const [dataTvr,setDataTvr] = useState({})
    const [dataEo,setDataEo] = useState({})
    const [dataRs,setDataRs] = useState({})
    const [dataKs,setDataKs] = useState({})

    const [dataKw,setDataKw] = useState({})
    const [dataDj,setDataDj] = useState({})
    const [dataSb,setDataSb] = useState({})
    const [dataLh,setDataLh] = useState({})
    const [dataPkm,setDataPkm] = useState({})
    
    const listkelurahan = [
      'Ampenan Selatan',
      'Ampenan Tengah',
      'Ampenan Utara',
      'Banjar',
      'Bintaro',
      'Dayan Peken',
      'Kebon Sari',
      'Pejarakan Karya',
      'Pejeruk',
      'Taman Sari',
      'Cakranegara Barat',
      'Cakranegara Selatan',
      'Cakranegara Selatan Baru',
      'Cakranegara Timur',
      'Cakranegara Utara',
      'Cilinaya',
      'Karang Taliwang',
      'Mayura',
      'Sapta Marga',
      'Sayang Sayang',
      'Mataram Timur',
      'Pegesangan',
      'Pegesangan Barat',
      'Pegesangan Timur',
      'Pagutan',
      'Pagutan Barat',
      'Pagutan Timur',
      'Pejanggik',
      'Punia',
      'Babakan',
      'Bertais',
      'Dasan Cermen',
      'Mandalika',
      'Selagalas',
      'Turida',
      'Abian Tubuh Baru',
      'Jempong Baru',
      'Karang Pule',
      'Kekalik Jaya',
      'Tanjung Karang',
      'Tanjung Karang Permai',
      'Dasan Agung',
      'Dasan Agung Baru',
      'Gomong',
      'Karang Baru',
      'Mataram Barat',
      'Monjok',
      'Monjok Barat',
      'Monjok Timur',
      'Rembiga',
    ]
    const listwarna = [
      '#20B2AA',
      '#DB7093',
      '#B22222',
      '#FFE4C4',
      '#191970',
      '#4169E1',
      '#87CEEB',
      '#FFF5EE',
      '#DA70D6',
      '#7CFC00',
      '#F5F5DC',
      '#00FA9A',
      '#48D1CC',
      '#00FF00',
      '#7FFF00',
      '#8B4513',
      '#FAFAD2',
      '#2E8B57',
      '#DAA520',
      '#FFD700',
      '#6A5ACD',
      '#FFFAF0',
      '#FAF0E6',
      '#008080',
      '#B0C4DE',
      '#FF8C00',
      '#DDA0DD',
      '#008000',
      '#00FF7F',
      '#F08080',
      '#00FFFF',
      '#EEE8AA',
      '#87CEFA',
      '#66CDAA',
      '#BA55D3',
      '#FF00FF',
      '#F0FFFF',
      '#D8BFD8',
      '#FFF0F5',
      '#FFA07A',
      '#CD853F',
      '#F0E68C',
      '#00008B',
      '#FFA07A',
      '#FFEBCD',
      '#B0E0E6',
      '#FFDEAD',
      '#F4A460',
      '#8B008B',
      '#FDF5E6',
    ]

    const listkecamatan = [
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Ampenan",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Cakranegara",
      "Mataram",
      "Mataram",
      "Mataram",
      "Mataram",
      "Mataram",
      "Mataram",
      "Mataram",
      "Mataram",
      "Mataram",
      "Sandubaya",
      "Sandubaya",
      "Sandubaya",
      "Sandubaya",
      "Sandubaya",
      "Sandubaya",
      "Sandubaya",
      "Sekarbela",
      "Sekarbela",
      "Sekarbela",
      "Sekarbela",
      "Sekarbela",
      "Selaparang",
      "Selaparang",
      "Selaparang",
      "Selaparang",
      "Selaparang",
      "Selaparang",
      "Selaparang",
      "Selaparang",
      "Selaparang"      
    ]
    //get data kawasan Lingkungan Hidup
    function getdataDj(e){
      try {
        firebaseDB.database().ref().child(e).child('kawasandgjasa').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataDj({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }
    } 
    //get data kawasan Lingkungan Hidup
     function getdataLh(e){
      try {
        firebaseDB.database().ref().child(e).child('kawasanlinghidup').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataLh({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }
    } 
    //get data kawasan wiasta unggulan
    function getdataKw(e){
      try {
        firebaseDB.database().ref().child(e).child('kawasanwisata').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
            setDataKw({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }
    }    
     //get data kawasan Sosbud
     function getdataSb(e){
      try {
        firebaseDB.database().ref().child(e).child('kawasansosbud').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
            setDataSb({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }
    }    


    //get data hotel
    function getdatahotel(e){
      try {
        firebaseDB.database().ref().child(e).child('hotel').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
            setDataHotel({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('restoran').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
            setDataResto({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('rumahmakan').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataRm({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('travel').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataTvr({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('eventorganizer').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataEo({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('rumahsakit').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataRs({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('klinikdanspesialis').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataKs({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }

      try {
        firebaseDB.database().ref().child(e).child('puskesmas').on('value',snapshot=>{
         
          if(snapshot.val()!=null)
          setDataPkm({
              ...snapshot.val()
            })
      })

      } catch (error) {
      }
    }    




    useEffect(() => { 
        
          const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [lng, lat],
            zoom: zoom
          });

          map.addControl(
            new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
            })
          );
          map.addControl(new mapboxgl.NavigationControl(), 'top-right');
          map.addControl(new mapboxgl.FullscreenControl());
          map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
          });
          map.on('load', function() {

            map.addSource('states', {
            'type': 'geojson',
            'data':
            'https://raw.githubusercontent.com/LaluRyandi/mapbox-gl-sepadan/master/mtr.geojson'
            });
    
            map.addLayer({
            'id': 'states-layer',
            'type': 'fill',
            'source': 'states',
            'paint': {
            'fill-color': [
              'match',
              ['get', 'KELDES'],
              'Ampenan Selatan','#20B2AA',
              'Ampenan Tengah','#DB7093',
              'Ampenan Utara','#B22222',
              'Banjar','#FFE4C4',
              'Bintaro','#191970',
              'Dayan Peken','#4169E1',
              'Kebon Sari','#87CEEB',
              'Pejarakan Karya','#FFF5EE',
              'Pejeruk','#DA70D6',
              'Taman Sari','#7CFC00',
              'Cakranegara Barat','#F5F5DC',
              'Cakranegara Selatan','#00FA9A',
              'Cakranegara Selatan Baru','#48D1CC',
              'Cakranegara Timur','#00FF00',
              'Cakranegara Utara','#7FFF00',
              'Cilinaya','#8B4513',
              'Karang Taliwang','#FAFAD2',
              'Mayura','#2E8B57',
              'Sapta Marga','#DAA520',
              'Sayang Sayang','#FFD700',
              'Mataram Timur','#6A5ACD',
              'Pagesangan','#FFFAF0',
              'Pagesangan Barat','#FAF0E6',
              'Pagesangan Timur','#008080',
              'Pagutan','#B0C4DE',
              'Pagutan Barat','#FF8C00',
              'Pagutan Timur','#DDA0DD',
              'Pejanggik','#008000',
              'Punia','#00FF7F',
              'Babakan','#F08080',
              'Bertais','#00FFFF',
              'Dasan cermen','#EEE8AA',
              'Mandalika','#87CEFA',
              'Selagalas','#66CDAA',
              'Turida','#BA55D3',
              'Abian Tubuh Baru','#FF00FF',
              'Jempong Baru','#F0FFFF',
              'Karang Pule','#D8BFD8',
              'Kekalik Jaya','#FFF0F5',
              'Tanjung Karang','#FFA07A',
              'Tanjung Karang Permai','#CD853F',
              'Dasan Agung','#F0E68C',
              'Dasan Agung Baru','#00008B',
              'Gomong','#FFA07A',
              'Karang Baru','#FFEBCD',
              'Mataram Barat','#B0E0E6',
              'Monjok','#FFDEAD',
              'Monjok Barat','#F4A460',
              'Monjok Timur','#8B008B',
              'Rembiga','#FDF5E6',
              '#ccc'
            ],
            'fill-outline-color': 'rgba(0, 0, 0, 1)',
            'fill-opacity' : 0.7}
            });
         
            map.on('click', 'states-layer', function(e) {
            
              var kelurahan = e.features[0].properties.KELDES;
              var kelurahan2 = e.features[0].properties.KELDES;
             
              if (kelurahan=="Tanjungkarang Permai") {
                kelurahan="tanjung_karang_permai"
              } else  if (kelurahan=="Madalika") {
                kelurahan="mandalika"
              }
              else {
                kelurahan = kelurahan.toLowerCase();
              }

              if (kelurahan2=="Madalika") {
                kelurahan2="Mandalika"
              }

              var finalc= kelurahan.replace(/ /g,"_")
          
              setNamaKelurahan( kelurahan2)
              setNamaKecamatan('Kec. '+e.features[0].properties.KEC)
              getdatahotel(finalc)
              getdataKw(finalc)
              getdataDj(finalc)
              getdataLh(finalc)
              getdataSb(finalc)
              // getdataResto(finalc)
            
            //   var finalc= kelurahan.replace(/ /g,"_")
            //   if(window.localStorage.getItem("click") == 0){
            //     setCurrentKelurahan(finalc)
            //     setCurrentKecamatan(e.features[0].properties.KEC.toLowerCase())
            //     setKKLoadMap({
           
            //       kelurahan : finalc,
            //       kecamatan :  e.features[0].properties.KEC
            //     })
            //     window.$('#modalkelurahan').modal('show')
            //   }else{
    
            //   }
            window.$('#exampleModal').modal('show')
                
                });
        
        
         
            map.on('mouseleave', 'states-layer', function() {
            map.getCanvas().style.cursor = '';
            });
            map.on('mousemove', function(e) {
                 
                var features = map.queryRenderedFeatures(e.point, { layers: ['states-layer'] });
                map.getCanvas().style.cursor = features.length ? 'pointer' : '';
              });
          });

 
    }, []);

    
    return(
        <>
        <div className='map-container' ref={mapContainerRef} />


        <div>
          
        </div>
        <div className="rounded-6  rightc ms-3 me-3  p-1" style={{backgroundColor:'rgba(0,0,0,0.2)',marginTop:'1%'}} role="group">
          <button type="button" className="rounded-6 pt-2 pb-2  ps-4 pe-4" style={{backgroundColor:'#ffffff'}}>Kelurahan</button>
          <Link  to="./petapotensikec" style={{textDecoration: 'none'}} type="button" className=" text-muted ps-4 pt-2 pb-2 pe-4 rounded-6">Kecamatan</Link>
          {/* <Link to="./peta" style={{textDecoration: 'none'}} type="button" className=" text-muted ps-4 pt-2 pb-2 pe-4 rounded-6">Pola Ruang</Link> */}
        </div>

        <div className=" rounded-6 rightc ms-3 me-3 " style={{backgroundColor:'rgba(0,0,0,0)',width: '24rem',height:'90vh',marginTop:'8vh'}}>
          <div className="card-body ">

            <div className="card p-3 rounded-6 shadow">
            <h6 className="card-subtitle mb-2 text-muted">Panel Potensi Kota Mataram</h6>
            <div className="p-5">
            <div className="row">
              <div className="col-md-4">
              <Link to="./kwisata"><img style={{cursor:'pointer'}}  src={pariwisata}   width={48} height={48}/></Link>
              </div>
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}  src={barangjasa} data-bs-toggle="modal" data-bs-target="#kawasandagangjasa" width={48} height={48}/>
              </div>
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}src={sosbud}  data-bs-toggle="modal" data-bs-target="#kawasansosbud" width={48} height={48}/>
              </div>
              
            </div><br/>
            <div className="row">
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}  src={nature} data-bs-toggle="modal" data-bs-target="#kawasanlingkungan" width={48} height={48}/>
              </div>
              <div className="col-md-4">
              <Link to="./faskes"><img style={{cursor:'pointer'}} src={kesehatan} width={48} height={48}/></Link>
              </div>
              <div className="col-md-4">
              <img style={{cursor:'pointer'}}   src={infop} data-bs-toggle="modal" data-bs-target="#infopotensimodal" width={48} height={48}/>
              </div>
              </div>
            </div>
            </div>

   

            <div className="card p-4 rounded-6 shadow mt-1">
            <h6 className="card-subtitle mb-2 text-muted">Legend Map ( List Kelurahan )</h6>
            <ol style={{overflow: 'scroll'}}  className="list-group rounded-6 list-group-numbered">

            {listkelurahan.map((listkelurahan,index)=>
                   
                   <div >
                          <li style={{cursor: 'pointer'}} key={index} className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{listkelurahan}</div>
                              Kec. {listkecamatan[index]}
                            </div>
                        
                            <span style={{backgroundColor : listwarna[index]}} className="badge rounded-pill">&nbsp;</span>
                        
                          </li>
                   </div>
                 )}


            </ol>
            </div>

            </div>
          </div>

  
          <div data-backdrop="false" class="modal fade"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div  class="modal-dialog modal-xl">
              <div  class="modal-content rounded-6 p-4">
            
                <div  class="modal-body">
                <h2 class="fw-bold mb-2">{namaKelurahan}</h2>            
                <h5 class="fw-bold mb-2 text-muted">{namaKecamatan}</h5>    
              
                        
                            <nav>
                              <div className="nav nav-tabs mb-4" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Kawasan Pariwisata</button>
                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Kawasan Perdagangan &amp; Jasa</button>
                                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Kawasan Sosial Budaya</button>
                                <button className="nav-link" id="nav-sosbud-tab" data-bs-toggle="tab" data-bs-target="#nav-sosbud" type="button" role="tab" aria-controls="nav-sosbud" aria-selected="false">Kawasan Lingkungan Hidup</button>
                                <button className="nav-link" id="nav-faskes-tab" data-bs-toggle="tab" data-bs-target="#nav-faskes" type="button" role="tab" aria-controls="nav-faskes" aria-selected="false">Fasilitas Kesehatan</button>
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <span className="p-3 fw-bold mb-5">Kawasan Bisnis/Unggulan :  
                                          {
                                             Object.keys(dataKw).map(id=>{    
                                              
                                              if (dataKw[id].nama==undefined) {
                                                return <span>Tidak ada data</span>
                                              }else{
                                                return <span>{dataKw[id].nama}</span>
                                              }
                                        
                                             
                                          })
                                        
                                        }
                                </span>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Hotel  &amp; Penginapan
                                      </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">

                                      <ul class="list-group list-group-numbered">
                                          
                                        {
                                             Object.keys(dataHotel).map(id=>{    
                                              
                                              if (dataHotel[id].nama==undefined) {
                                                return <span>Tidak ada data</span>
                                              }else if (dataHotel[id].geolokasi=="NULL") {
                                                return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                  <div className="fw-bold">{dataHotel[id].nama}</div>
                                                  {dataHotel[id].alamat}
                                                  </div>
                                                  
                                                </li>
                                              }
                                              else{
                                                return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                  <div className="fw-bold">{dataHotel[id].nama}</div>
                                                  {dataHotel[id].alamat}
                                                  </div>
                                                    <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataHotel[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                </li>
                                              }
                                             
                                        
                                             
                                          })
                                        
                                        }
                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                      Kuliner
                                      </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">
                                        <code>A. Restoran</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataResto).map(id=>{    
                                                
                                                if (dataResto[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataResto[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataResto[id].nama}</div>
                                                    {dataResto[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataResto[id].nama}</div>
                                                    {dataResto[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataResto[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
  
                                        <code>B. Rumah Makan</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataRm).map(id=>{    
                                                
                                                if (dataRm[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataRm[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRm[id].nama}</div>
                                                    {dataRm[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRm[id].nama}</div>
                                                    {dataRm[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataRm[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul> 
                                        
                                        </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                      Traveler &amp; Event Organizer
                                      </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                      <div className="accordion-body">
                                        <code>A. Traveler</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataTvr).map(id=>{    
                                                
                                                if (dataTvr[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataTvr[id].nama}</div>
                                                    {dataTvr[id].alamat}
                                                    </div>
                                                     
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul> 
                                        <code>B. Event Organizer</code><br/>
                                        <ul class="list-group list-group-numbered mb-4">
                                          
                                          {
                                               Object.keys(dataEo).map(id=>{    
                                                
                                                if (dataEo[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataEo[id].nama}</div>
                                                    {dataEo[id].alamat}
                                                    </div>
                                                     
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul> 
                                        .</div>
                                    </div>
                                  </div>
                                </div>
                                  
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                  
                                <span className="p-3 fw-bold mb-5">Kawasan Bisnis/Unggulan :  
                                          {
                                             Object.keys(dataDj).map(id=>{    
                                              
                                              if (dataDj[id].nama==undefined) {
                                                return <span>Tidak ada data</span>
                                              }else{
                                                return <span>{dataDj[id].nama}</span>
                                              }
                                        
                                             
                                          })
                                        
                                        }
                                </span>

                                </div>
                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <span className="p-3 fw-bold mb-5">Kawasan Bisnis/Unggulan :  
                                          {
                                             Object.keys(dataSb).map(id=>{    
                                              
                                              if (dataSb[id].nama==undefined) {
                                                return <span>Tidak ada data</span>
                                              }else{
                                                return <span>{dataSb[id].nama}</span>
                                              }
                                        
                                             
                                          })
                                        
                                        }
                                </span>
                                </div>
                                <div className="tab-pane fade" id="nav-sosbud" role="tabpanel" aria-labelledby="nav-sosbud-tab">
                                <span className="p-3 fw-bold mb-5">Kawasan Bisnis/Unggulan :  
                                          {
                                             Object.keys(dataLh).map(id=>{    
                                              
                                              if (dataLh[id].nama==undefined) {
                                                return <span>Tidak ada data</span>
                                              }else{
                                                return <span>{dataLh[id].nama}</span>
                                              }
                                        
                                             
                                          })
                                        
                                        }
                                </span>
                                </div>

                              <div className="tab-pane fade " id="nav-faskes" role="tabpanel" aria-labelledby="nav-faskes-tab">
                              <div className="accordion accordion-flush" id="accordionFlushExample1">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="flush-haedF">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseF" aria-expanded="false" aria-controls="flush-collapseF">
                                      Rumah Sakit
                                    </button>
                                  </h2>
                                  <div id="flush-collapseF" className="accordion-collapse collapse" aria-labelledby="flush-haedF" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    <ul class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataRs).map(id=>{    
                                                
                                                if (dataRs[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataRs[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRs[id].nama}</div>
                                                    {dataRs[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRs[id].nama}</div>
                                                    {dataRs[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataRs[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="flush-headingK">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwoK" aria-expanded="false" aria-controls="flush-collapseTwoK">
                                    Puskesmas
                                    </button>
                                  </h2>
                                  <div id="flush-collapseTwoK" className="accordion-collapse collapse" aria-labelledby="flush-headingK" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    <ul class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataPkm).map(id=>{    
                                                
                                                if (dataPkm[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataPkm[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataPkm[id].nama}</div>
                                                    {dataPkm[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataPkm[id].nama}</div>
                                                    {dataPkm[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataPkm[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="flush-headingThreeE">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThreeE" aria-expanded="false" aria-controls="flush-collapseThreeE">
                                    Klinik &amp; Spesialis
                                    </button>
                                  </h2>
                                  <div id="flush-collapseThreeE" className="accordion-collapse collapse" aria-labelledby="flush-headingThreeE" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                    <ul class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataKs).map(id=>{    
                                                
                                                if (dataKs[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataKs[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataKs[id].nama}</div>
                                                    {dataKs[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataKs[id].nama}</div>
                                                    {dataKs[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataKs[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ul>
                                          </div>
                                  </div>
                                </div>
                              </div> 
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                
                  </div>
                        </div>
                      
                  </div>
                 
                
                </div>
          
          
               
        
   

              {/* Modal Perdagangan dan Jasa*/}
            <div className="modal fade" id="kawasandagangjasa" tabIndex={-1} aria-labelledby="kawasandagangjasaLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Kawasan Perdagangan &amp; Jasa</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="kwunggulan-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kwunggulan" aria-expanded="false" aria-controls="kwunggulan">
                            <h6 class="mb-0">Kawasan Bisnis/Unggulan : </h6>
                            </button>
                          </h2>
                          <div id="kwunggulan" className="accordion-collapse collapse" aria-labelledby="kwunggulan-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                  1. Pusat perdagangan Ampenan di Kelurahan Dayen Peken, Kelurahan Ampenan Tengah, dan Kelurahan Ampenan Selatan (Kecamatan Ampenan);<br/><br/>
                                  2. Pusat perdagangan grosir dan pusat bisnis Cakranegara di Kelurahan Cakranegara Barat, Kelurahan Cilinaya, Kelurahan Mayura, Kelurahan Cakranegara Timur, dan Kelurahan Cakranegara Selatan;<br/><br/>
                                  3. Kawasan Bertais dan Kawasan Mandalika
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>

            {/* Modal Sosial Budaya*/}
            <div className="modal fade" id="kawasansosbud" tabIndex={-1} aria-labelledby="kawasansosbud" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Kawasan Sosial Budaya</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="kwunggulan-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kwunggulan" aria-expanded="false" aria-controls="kwunggulan">
                            <h6 class="mb-0">Kawasan Bisnis/Unggulan : </h6>
                            </button>
                          </h2>
                          <div id="kwunggulan" className="accordion-collapse collapse" aria-labelledby="kwunggulan-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                1. Kawasan Bintaro di Kelurahan Bintaro (Kecamatan Ampenan);<br/><br/>
                                2. Kawasan Makam Van Ham di Kelurahan Cilinaya (Kecamatan Cakranegara);<br/><br/>
                                3. Kawasan Pusat Kajian Islam (Islamic Center) di Kelurahan Dasan Agung;<br/><br/>
                                4. Kawasan Kota Tua Ampenan di kelurahan Ampenan Tengah dan Ampenan Selatan (Kecamatan Ampenan).
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          
            {/* Modal Kawasan Lingkungan Hidup*/}
            <div className="modal fade" id="kawasanlingkungan" tabIndex={-1} aria-labelledby="kawasanlingkungan" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Kawasan Lingkungan Hidup</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="kwunggulan-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kwunggulan" aria-expanded="false" aria-controls="kwunggulan">
                            <h6 class="mb-0">Kawasan Bisnis/Unggulan : </h6>
                            </button>
                          </h2>
                          <div id="kwunggulan" className="accordion-collapse collapse" aria-labelledby="kwunggulan-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                1. Kawasan konservasi di sepanjang Sungai Midang, Sungai Jangkok, Sungai Ancar, dan Sungai Brenyok;<br/><br/>
                                2. Kawasan koservasi sempadan pantai Selat Lombok sepanjang 8-9 km;<br/><br/>
                                3. Kawasan lingdung di kelurahan Pagutan Timur (Kecamatan Mataram) serta kelurahan Sayang-sayang dan Selagalas (Kecamatan Sandubaya);<br/><br/>
                                4. Kawasan hutan Kota di tiap tanah pecatu yang terdapat di Kota Mataram.
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            
       
               {/* Modal Info Potensi*/}
               <div className="modal fade" id="infopotensimodal" tabIndex={-1} aria-labelledby="infopotensimodal" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content rounded-6">
                  <div className="modal-body">
                  <h2 class="fw-bold mb-2">Potensi Kota Mataram</h2>
                
                      <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ipkep-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ipkep" aria-expanded="false" aria-controls="ipkep">
                            <h6 class="mb-0">kawasan potensi pendukung ekonomi cepat tumbuh</h6>
                            </button>
                          </h2>
                          <div id="ipkep" className="accordion-collapse collapse" aria-labelledby="ipkep-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                1. Kawasan Strategis Bidang Pariwisata : Kawasan Mutiara Sekarbela, Kawasan Rekreasi Pantai Dan Situs Makam Loang Baloq<br/><br/>
                                2. Kawasan Strategis Bidang Perdagangan Dan Jasa : pusat perdagangan grosir dan bisnis di Cakranegara, Ampenan dan Bertais<br/><br/>
                                3. Kawasan Strategis Dari Sudut Kepentingan Sosial Budaya : Pusat Kajian Islam di Kelurahan Dasan Agung dan Kawasan Kota Tua Ampenan<br/><br/>
                                4. Kawasan Strategis Bidang Daya Dukung Lingkungan Hidup : Kawasan Lindung di Pagutan Timur, Sayang-Sayang dan Selagalas
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ipsip-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ipsip-collapseTwo" aria-expanded="false" aria-controls="ipsip-collapseTwo">
                            <h6 class="mb-0">Komoditas penunjang perkembangan sektor industry pengolahan</h6>
                            </button>
                          </h2>
                          <div id="ipsip-collapseTwo" className="accordion-collapse collapse" aria-labelledby="ipsip-headingTwo" data-bs-parent="#accordionFlushExample">
                           <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                  1. Budidaya kangkung di Pesongoran<br/><br/>
                                  2. Industri tahu dan tempe di Kekalik dan Abian Tubuh<br/><br/>
                                  3. Industri olahan rumput laut di Cakranegara<br/><br/>
                                  4. Produksi dan perdagangan perhiasan emas, perak dan mutiara di Sekarbela, Karang Genteng dan Karang Mas-Mas
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ippism-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ippism-collapseThree" aria-expanded="false" aria-controls="ippism-collapseThree">
                            <h6 class="mb-0">Peluang investasi dalam sekala menengah yang potensial di Kota Mataram</h6>
                            </button>
                          </h2>
                          <div id="ippism-collapseThree" className="accordion-collapse collapse" aria-labelledby="ippism-headingThree" data-bs-parent="#accordionFlushExample">
                          <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                  1. Jasa telekomunikasi seluler<br/><br/>
                                  2. Perdagangan<br/><br/>
                                  3. Jasa rekreasi wisata<br/><br/>
                                  4. Jasa konsultasi pengembangan bisnis dan manajemen<br/><br/>
                                  5. Biro perjalanan wisata<br/><br/>
                                  6. Jasa penyediaan gedung perkantoran dan pusat bisnis
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="ipdppm-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ipdppm-collapseThree" aria-expanded="false" aria-controls="ipdppm-collapseThree">
                            <h6 class="mb-0"> Investasi anda akan didukung dengan penyediaan sarana dan prasarana yang memadai seperti</h6>
                            </button>
                          </h2>
                          <div id="ipdppm-collapseThree" className="accordion-collapse collapse" aria-labelledby="ipdppm-headingThree" data-bs-parent="#accordionFlushExample">
                           <div className="accordion-body">
                              <p className="fs-6" style={{textAlign: "justify"}}>
                    
                                    1. UMKM (Usaha Mikro Kecil Menengah)<br/><br/>
                                    2. Tenaga kerja<br/><br/>
                                    3. Ketersediaan listrik<br/><br/>
                                    4. Distribusi air<br/><br/>
                                    5. Infrastruktur jalan dan transportasi<br/><br/>
                                    6. Perbankan
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                 
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
        </>
    )
}