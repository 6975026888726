import { React, useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '../css/map.css'
import firebaseDB from './firebase'
import gmap from '../assets/img/gmap.png'

mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmRpYXRzYW5pIiwiYSI6ImNqajRjMHJ6bzE1ZDAza3I1a252Y2Y0M2kifQ.x8e3S41RKLHRw_wnGftkjA"

export default function Faskes(){

    const mapContainerRef = useRef(null);
    const [lng, setLng] = useState(116.100487);
    const [lat, setLat] = useState(-8.597081);
    const [zoom, setZoom] = useState(12);

    const [dataRs,setDataRs] = useState({})
    const [dataKs,setDataKs] = useState({})
    const [dataPkm,setDataPkm] = useState({})
        

    const listkelurahan = [
        'Ampenan Selatan',
        'Ampenan Tengah',
        'Ampenan Utara',
        'Banjar',
        'Bintaro',
        'Dayan Peken',
        'Kebon Sari',
        'Pejarakan Karya',
        'Pejeruk',
        'Taman Sari',
        'Cakranegara Barat',
        'Cakranegara Selatan',
        'Cakranegara Selatan Baru',
        'Cakranegara Timur',
        'Cakranegara Utara',
        'Cilinaya',
        'Karang Taliwang',
        'Mayura',
        'Sapta Marga',
        'Sayang Sayang',
        'Mataram Timur',
        'Pegesangan',
        'Pegesangan Barat',
        'Pegesangan Timur',
        'Pagutan',
        'Pagutan Barat',
        'Pagutan Timur',
        'Pejanggik',
        'Punia',
        'Babakan',
        'Bertais',
        'Dasan Cermen',
        'Mandalika',
        'Selagalas',
        'Turida',
        'Abian Tubuh Baru',
        'Jempong Baru',
        'Karang Pule',
        'Kekalik Jaya',
        'Tanjung Karang',
        'Tanjung Karang Permai',
        'Dasan Agung',
        'Dasan Agung Baru',
        'Gomong',
        'Karang Baru',
        'Mataram Barat',
        'Monjok',
        'Monjok Barat',
        'Monjok Timur',
        'Rembiga',
      ]
      const listwarna = [
        '#20B2AA',
        '#DB7093',
        '#B22222',
        '#FFE4C4',
        '#191970',
        '#4169E1',
        '#87CEEB',
        '#FFF5EE',
        '#DA70D6',
        '#7CFC00',
        '#F5F5DC',
        '#00FA9A',
        '#48D1CC',
        '#00FF00',
        '#7FFF00',
        '#8B4513',
        '#FAFAD2',
        '#2E8B57',
        '#DAA520',
        '#FFD700',
        '#6A5ACD',
        '#FFFAF0',
        '#FAF0E6',
        '#008080',
        '#B0C4DE',
        '#FF8C00',
        '#DDA0DD',
        '#008000',
        '#00FF7F',
        '#F08080',
        '#00FFFF',
        '#EEE8AA',
        '#87CEFA',
        '#66CDAA',
        '#BA55D3',
        '#FF00FF',
        '#F0FFFF',
        '#D8BFD8',
        '#FFF0F5',
        '#FFA07A',
        '#CD853F',
        '#F0E68C',
        '#00008B',
        '#FFA07A',
        '#FFEBCD',
        '#B0E0E6',
        '#FFDEAD',
        '#F4A460',
        '#8B008B',
        '#FDF5E6',
      ]
  
      const listkecamatan = [
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Ampenan",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Cakranegara",
        "Mataram",
        "Mataram",
        "Mataram",
        "Mataram",
        "Mataram",
        "Mataram",
        "Mataram",
        "Mataram",
        "Mataram",
        "Sandubaya",
        "Sandubaya",
        "Sandubaya",
        "Sandubaya",
        "Sandubaya",
        "Sandubaya",
        "Sandubaya",
        "Sekarbela",
        "Sekarbela",
        "Sekarbela",
        "Sekarbela",
        "Sekarbela",
        "Selaparang",
        "Selaparang",
        "Selaparang",
        "Selaparang",
        "Selaparang",
        "Selaparang",
        "Selaparang",
        "Selaparang",
        "Selaparang"      
      ]


    function getdatahotel(){
        try {
            firebaseDB.database().ref().child('kotamataram').child('rumahsakit').on('value',snapshot=>{
             
              if(snapshot.val()!=null)
              setDataRs({
                  ...snapshot.val()
                })
          })
    
          } catch (error) {
          }
    
          try {
            firebaseDB.database().ref().child('kotamataram').child('klinikdanspesialis').on('value',snapshot=>{
             
              if(snapshot.val()!=null)
              setDataKs({
                  ...snapshot.val()
                })
          })
    
          } catch (error) {
          }

          try {
            firebaseDB.database().ref().child('kotamataram').child('puskesmas').on('value',snapshot=>{
             
              if(snapshot.val()!=null)
              setDataPkm({
                  ...snapshot.val()
                })
          })
    
          } catch (error) {
          }
    }
    useEffect(() => { 
        
        getdatahotel()

        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/light-v10',
          center: [lng, lat],
          zoom: zoom
        });

        map.addControl(
            new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
            })
          );
          map.addControl(new mapboxgl.NavigationControl(), 'top-right');
          map.addControl(new mapboxgl.FullscreenControl());
        map.on('load', function() {

            map.addSource('states', {
            'type': 'geojson',
            'data':
            'https://raw.githubusercontent.com/LaluRyandi/mapbox-gl-sepadan/master/mtr.geojson'
            });
    
            map.addLayer({
            'id': 'states-layer',
            'type': 'fill',
            'source': 'states',
            'paint': {
            'fill-color': [
              'match',
              ['get', 'KELDES'],
              'Ampenan Selatan','#20B2AA',
              'Ampenan Tengah','#DB7093',
              'Ampenan Utara','#B22222',
              'Banjar','#FFE4C4',
              'Bintaro','#191970',
              'Dayan Peken','#4169E1',
              'Kebon Sari','#87CEEB',
              'Pejarakan Karya','#FFF5EE',
              'Pejeruk','#DA70D6',
              'Taman Sari','#7CFC00',
              'Cakranegara Barat','#F5F5DC',
              'Cakranegara Selatan','#00FA9A',
              'Cakranegara Selatan Baru','#48D1CC',
              'Cakranegara Timur','#00FF00',
              'Cakranegara Utara','#7FFF00',
              'Cilinaya','#8B4513',
              'Karang Taliwang','#FAFAD2',
              'Mayura','#2E8B57',
              'Sapta Marga','#DAA520',
              'Sayang Sayang','#FFD700',
              'Mataram Timur','#6A5ACD',
              'Pagesangan','#FFFAF0',
              'Pagesangan Barat','#FAF0E6',
              'Pagesangan Timur','#008080',
              'Pagutan','#B0C4DE',
              'Pagutan Barat','#FF8C00',
              'Pagutan Timur','#DDA0DD',
              'Pejanggik','#008000',
              'Punia','#00FF7F',
              'Babakan','#F08080',
              'Bertais','#00FFFF',
              'Dasan cermen','#EEE8AA',
              'Mandalika','#87CEFA',
              'Selagalas','#66CDAA',
              'Turida','#BA55D3',
              'Abian Tubuh Baru','#FF00FF',
              'Jempong Baru','#F0FFFF',
              'Karang Pule','#D8BFD8',
              'Kekalik Jaya','#FFF0F5',
              'Tanjung Karang','#FFA07A',
              'Tanjung Karang Permai','#CD853F',
              'Dasan Agung','#F0E68C',
              'Dasan Agung Baru','#00008B',
              'Gomong','#FFA07A',
              'Karang Baru','#FFEBCD',
              'Mataram Barat','#B0E0E6',
              'Monjok','#FFDEAD',
              'Monjok Barat','#F4A460',
              'Monjok Timur','#8B008B',
              'Rembiga','#FDF5E6',
              '#ccc'
            ],
            'fill-outline-color': 'rgba(0, 0, 0, 1)',
            'fill-opacity' : 0.7}
            });
         
            map.on('click', 'states-layer', function(e) {

            
                // new mapboxgl.Popup()
                // .setLngLat(e.lngLat)
                // .setHTML('<h6>'+e.features[0].properties.KELDES+'</h6>')
                // .addTo(map);
                
            });
        
        
         
            map.on('mouseleave', 'states-layer', function() {
            map.getCanvas().style.cursor = '';
            });
            map.on('mousemove', function(e) {
                 
                var features = map.queryRenderedFeatures(e.point, { layers: ['states-layer'] });
                map.getCanvas().style.cursor = features.length ? 'pointer' : '';
              });
          });

        map.on('load', function() {
            // Add the circle layer to the map
            
            map.addLayer({
              id: 'pedestrian_volume',
              type: 'circle',
              source: {
                type: 'vector',
                url: 'mapbox://ryandiatsani.4982mcq1' // Your Mapbox tileset Map ID
              },
              'source-layer': 'rsklinik-ccua33', // name of tilesets,
              paint: {
      
            // Add data-driven styles for circle radius
            'circle-radius': {
              'base': 4,
              'stops': [[12, 4], [22, 180]]
            },
            
            'circle-color': [
          'match',
          ['get', 'Tipe'],
          'rumah_sakit', '#001d3d',
          'klinik_spesialis', '#ee9b00',
          'puskesmas', '#b30000',
         
        /* other */ '#ccc'
        ],
            'circle-opacity': 1
          }
            });
      
     
          });

          map.on('click', 'pedestrian_volume', function(e) {

            
            new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(
                
                '<h6>'+e.features[0].properties.Nama+'</h6><br>'+'<span>'+e.features[0].properties.Alamat+' ('+e.features[0].properties.Kelurahan+' )'
                
                )
            .addTo(map);
            
        });
    
    
     
        map.on('mouseleave', 'pedestrian_volume', function() {
        map.getCanvas().style.cursor = '';
        });
        map.on('mousemove', function(e) {
             
            var features = map.queryRenderedFeatures(e.point, { layers: ['pedestrian_volume'] });
            map.getCanvas().style.cursor = features.length ? 'pointer' : '';
          });


    }, []);

        return(

            <>

        <div className="container">
          <header className="blog-header py-3">
            <div className="row flex-nowrap justify-content-between align-items-center">
              <div className="col-4 pt-1">
              </div>
              <div className="col-4 text-center">
                <h2 className="fw-bold">Fasilitas Kesehatan</h2>
              </div>
              <div className="col-4 d-flex justify-content-end align-items-center">
                  
              </div>
            </div>
          </header>
        </div>
        <main className="container">
          <div className="mb-4" style={{height: '480px', width: '100%'}} ref={mapContainerRef} >
            
          </div>
          <div className="row g-5">
            <div className="col-md-6">
              <h5 className="pb-4 mb-4 fst-italic border-bottom">
                Detail Informasi Fasilitas Kesehatan Kota Mataram
              </h5>
            <article className="blog-post">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   Rumah Sakit
                    </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                    <code>Total 18 Se Kota Mataram </code><br/>
                            <ol style={{overflow: 'scroll'}} class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataRs).map(id=>{    
                                                
                                                if (dataRs[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataRs[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRs[id].nama}</div>
                                                    {dataRs[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataRs[id].nama}</div>
                                                    {dataRs[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataRs[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                              </ol>
                    </div>
                </div>
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                   Puskesmas
                    </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                    <code>Total 11 Se Kota Mataram </code><br/>
                    <ol style={{overflow: 'scroll'}} class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataPkm).map(id=>{    
                                                
                                                if (dataPkm[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataPkm[id].nama}</div>
                                                    {dataPkm[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataPkm[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                              </ol>
                    </div>
                </div>
                </div>
                <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Klinik  &amp; Spesialis
                    </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                    <code>Total 66 Se Kota Mataram</code><br/>
                            <ol style={{overflow: 'scroll'}} class="list-group list-group-numbered">
                                          
                                          {
                                               Object.keys(dataKs).map(id=>{    
                                                
                                                if (dataKs[id].nama==undefined) {
                                                  return <span>Tidak ada data</span>
                                                }else if (dataKs[id].geolokasi=="NULL") {
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataKs[id].nama}</div>
                                                    {dataKs[id].alamat}
                                                    </div>
                                                    
                                                  </li>
                                                }
                                                
                                                else{
                                                  return  <li className="list-group-item d-flex justify-content-between align-items-start">
                                                  <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{dataKs[id].nama}</div>
                                                    {dataKs[id].alamat}
                                                    </div>
                                                      <a target="_blank" rel="noopener noreferrer" href={"https://maps.google.com/?q="+dataKs[id].nama}><img src={gmap} width={45} height={45}/></a>
                                                  </li>
                                                }
                                               
                                          
                                               
                                            })
                                          
                                          }
                                          </ol>
                    </div>
                </div>
                </div>
            </div>
            </article>
             
            </div>

            <div className="col-md-6">
              <div className="position-sticky" style={{top: '2rem'}}>
                <div className="p-4 mb-3 bg-light rounded">
                  <h5 >Legend Map </h5>
                  <h6 className="mt-3">Warna titik kordinat</h6>
                  <div class="btn-group mt-2 " role="group">
                 
                    <h6 className="p-1 rounded-6 text-white" style={{backgroundColor : '#001d3d'}}>Rumah Sakit</h6>
                    <h6 className="p-1 rounded-6"style={{backgroundColor : '#b30000'}} >Puskesmas</h6>
                    <h6 className="p-1 rounded-6"style={{backgroundColor : '#ee9b00'}} >Klinik  &amp; Spesialis</h6>
                    

                </div>
                    <h6 className="mt-4">Warna Kelurahan</h6> <button type="button" class="btn btn-secondary " data-bs-toggle="modal" data-bs-target="#warnkelkw"> Tampilkan</button>
                </div>  
      
              </div>

             
            </div>
          </div>
        </main>
    

                {/* Modal */}
                <div className="modal fade" id="warnkelkw" tabIndex={-1} aria-labelledby="warnkelkwLabel" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content rounded-6">
                      
                        <div className="modal-body">
                        <ol style={{overflow: 'scroll'}}  className="list-group rounded-6 list-group-numbered">

                                {listkelurahan.map((listkelurahan,index)=>
                                    
                                    <div >
                                            <li style={{cursor: 'pointer'}} key={index} className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                <div className="fw-bold">{listkelurahan}</div>
                                               Kec. {listkecamatan[index]}
                                                </div>
                                            
                                                <span style={{backgroundColor : listwarna[index]}} className="badge rounded-pill">&nbsp;</span>
                                            
                                            </li>
                                    </div>
                                    )}


                                </ol>
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
                        </div>
                    </div>
                    </div>
                </div>

                
            </>
        )

}