import firebase from 'firebase'
import 'firebase/database'
var firebaseConfig = {
    apiKey: "AIzaSyBhS62QJNqQbC1NtIfJN25W0s7raBCsagg",
    authDomain: "petainvestasi-mataram.firebaseapp.com",
    databaseURL: "https://petainvestasi-mataram-default-rtdb.firebaseio.com",
    projectId: "petainvestasi-mataram",
    storageBucket: "petainvestasi-mataram.appspot.com",
    messagingSenderId: "612084716431",
    appId: "1:612084716431:web:d1f03d15513ea233c631fa",
    measurementId: "G-G19JJ6JJ1F"
};
  // Initialize Firebase
  var firebaseDB = firebase.initializeApp(firebaseConfig);
  
  export default firebaseDB;
