import { React, useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '../css/map.css'

import {Link} from 'react-router-dom'
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmRpYXRzYW5pIiwiYSI6ImNqajRjMHJ6bzE1ZDAza3I1a252Y2Y0M2kifQ.x8e3S41RKLHRw_wnGftkjA"

export default function Peta(){
    const mapContainerRef = useRef(null);
    const [lng, setLng] = useState(116.100487);
    const [lat, setLat] = useState(-8.597081);
    const [zoom, setZoom] = useState(12);

    const [polaloadmap,setPolaloadmap] = useState({
        name : '',
        kelas1 : '',
        kelas2 : '',
        kelas3 : '',
        sumber:'',
        luasha:''
      
      })

    useEffect(() => { 

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/ryandiatsani/ckrobbloa18qp17pc1wjm2oyi',
            center: [lng, lat],
            zoom: zoom
          });

          map.addControl(
            new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl
            })
          );
          map.addControl(new mapboxgl.NavigationControl(), 'top-right');
          map.addControl(new mapboxgl.FullscreenControl());
          map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
          });
   
          map.on('load', function() {

            map.addSource('states', {
            'type': 'geojson',
            'data':
            'https://raw.githubusercontent.com/LaluRyandi/mapbox-gl-sepadan/master/PETA_RENCANA_POLA_RUANG_FINAL_REVISI_RTRW.geojson'
            });
    
            map.addLayer({
            'id': 'states-layer',
            'type': 'fill',
            'source': 'states',
            'paint': {
            'fill-color': 'rgba(200, 100, 240, 0)',
            'fill-outline-color': 'rgba(255, 255, 255, 1)',
            'fill-opacity' : 0
            }
            });
         
            map.on('click', 'states-layer', function(e) {

                setPolaloadmap({
                    
                    name :  e.features[0].properties.Name,
                    kelas1 : e.features[0].properties.Kelas_I,
                    kelas2 : e.features[0].properties.Kelas_II,
                    kelas3 : e.features[0].properties.Kelas_III,
                    sumber : e.features[0].properties.Sumber,
                    luasha : e.features[0].properties.Luas_Ha,

                })
            
                window.$('#exampleModal').modal('show')
                
                });
        
        
         
            map.on('mouseleave', 'states-layer', function() {
            map.getCanvas().style.cursor = '';
            });
            map.on('mousemove', function(e) {
                 
                var features = map.queryRenderedFeatures(e.point, { layers: ['states-layer'] });
                map.getCanvas().style.cursor = features.length ? 'pointer' : '';
              });
        });
             
 
    }, []);

    return(
        <>
                <div className='map-container' ref={mapContainerRef} />
                <div className="rounded-6  rightc ms-3 me-3  p-1" style={{backgroundColor:'rgba(0,0,0,0.2)',marginTop:'1%'}} role="group">
          <Link  to="./petapotensi" style={{textDecoration: 'none'}} type="button" className="rounded-6 pt-2 pb-2  ps-4 pe-4" >Kelurahan</Link>
          <Link  to="./petapotensikec" style={{textDecoration: 'none'}} type="button" className=" text-muted ps-4 pt-2 pb-2 pe-4 rounded-6">Kecamatan</Link>
          <button type="button"  style={{backgroundColor:'#ffffff'}} className=" text-muted ps-4 pt-2 pb-2 pe-4 rounded-6">Pola Ruang</button>
        </div>
                  {/* Modal */}
                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{polaloadmap.name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                        <p>Kelas I : {polaloadmap.kelas1}</p>
                        <p>Kelas II :  {polaloadmap.kelas2}</p>
                        <p>Kelas III :  {polaloadmap.kelas3}</p>
                        <p>Sumber : {polaloadmap.sumber}</p>
                        <p>Luas_Ha : {polaloadmap.luasha}</p>
    
                        </div>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      
                        </div>
                    </div>
                    </div>
                </div>
        </>
    )
}