import logo from './logo.svg';
import './App.css';
import {Route,BrowserRouter as Router, Switch} from 'react-router-dom'
import Mainpage from './pages/mainpage'
import Petapage from './pages/peta'
import Mappotensi from './pages/mappotensi'
import Kwisata from './pages/kwisata'
import Faskes from './pages/faskes'
import Mappotensikec from './pages/mappotensikec'
import Download from './pages/download'
import Panduan from './pages/panduan'

function App() {
  return (
    <Router>
    <Switch>
      <Route path="/" exact component={Mainpage} />
      <Route path="/peta" exact component={Petapage} />
      <Route path="/petapotensi" exact component={Mappotensi} />
      <Route path="/kwisata" exact component={Kwisata} />
      <Route path="/faskes" exact component={Faskes} />
      <Route path="/petapotensikec" exact component={Mappotensikec} />
      <Route path="/download" exact component={Download} />
      <Route path="/panduan" exact component={Panduan} />
      </Switch>
    </Router>
  );
}

export default App;
